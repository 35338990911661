import React, { Component, Fragment } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import { BlogListWrapper, IsActive } from "../components/Style/blog-list"
import Seo from "../components/Seo"
import PanelHeader from "../../src/components/PanelHeader/index"

export default class BlogList extends Component {
  render() {
    let posts = this.props.data.allMarkdownRemark.edges
    const { numPages, currentPage } = this.props.pageContext

    return (
      <Fragment>
        <Seo title={`Latest Posts`} description={`Get the latest posts and updates for all things tennis!`} />

        <BlogListWrapper>
          <Layout>
            <PanelHeader
              panelTitle="Latest Posts"
              panelHeadingText="Here you will find the latest news and updates on all things
              tennis."
            />
            <div id="list-main">
              <div id="listings">
                {posts.map(({ node }) => {
                  const title = node.frontmatter.title || node.fields.slug
                  const featuredImgFluid = node.frontmatter.featuredImage.childImageSharp.fluid
                  const theExcerpt = node.excerpt
                  const theTags = node.frontmatter.tags
                  const theSlug = node.fields.slug
                  const theAuthor = node.frontmatter.author

                  return (
                    <Link to={theSlug} key={theSlug}>
                      <div className="post">
                        <Img fluid={featuredImgFluid} />
                        <div className="post-info">
                          <div className="post-tags">
                            {theTags.map((tag, tagIndex) => (
                              <small key={tagIndex}>{tag}</small>
                            ))}
                          </div>
                          <h4>{title}</h4>
                          <p>{theExcerpt}</p>
                          <div className="author-box">
                            <p>{new Date(node.frontmatter.date).toLocaleDateString()}</p> | <p>By {theAuthor}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
              <div id="pagination-section">
                <div id="inner">
                  {currentPage > 4 && (
                    <div id="first-page-link" className="skip-link">
                      <Link to={`/blogs`}>First page</Link>
                    </div>
                  )}

                  {Array.from({ length: numPages }, (_, i) => (
                    <div key={i}>
                      {i + 1 > currentPage - 4 && i + 1 < currentPage && (
                        <Fragment>{i + 1 === 1 ? <Link to={`/blogs`}>{i + 1}</Link> : <Link to={`/blogs/${i + 1}`}>{i + 1}</Link>}</Fragment>
                      )}

                      {i + 1 === currentPage && <IsActive active={true}>{i + 1}</IsActive>}

                      {i + 1 < currentPage + 4 && i + 1 > currentPage && <Link to={`/blogs/${i + 1}`}>{i + 1}</Link>}
                    </div>
                  ))}

                  {currentPage < numPages - 3 && (
                    <div id="last-page-link" className="skip-link">
                      <Link to={`/blogs/${numPages}`}>Last page</Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Layout>
        </BlogListWrapper>
      </Fragment>
    )
  }
}

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: $limit, skip: $skip, filter: { fileAbsolutePath: { regex: "/blogs/" } }) {
      edges {
        node {
          fields {
            slug
            sourceName
          }
          excerpt(pruneLength: 160)
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tags
            date
            author
          }
        }
      }
    }
  }
`
