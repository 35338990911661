import styled, { css } from "styled-components"

export const BlogListWrapper = styled.div`
  #list-main {
    background-color: white;
    background-color: white;
    padding-bottom: 60px;

    h1,
    p.intro {
      padding: 20px 100px;
    }
    h1 {
      padding-bottom: 0;
      margin-bottom: 0;
    }
    p {
      padding-top: 0;
    }

    #listings {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      background-color: white;
      padding: 80px 100px;

      a {
        cursor: pointer;
        text-decoration: none;
        color: ${(props) => props.theme.colors.black};

        :hover {
          opacity: 0.8;
        }
        .post {
          width: 330px;
          margin-bottom: 20px;
          border-radius: 3px;

          .post-info {
            background-color: ${(props) => props.theme.colors.white};
            padding: 20px;

            .post-tags {
              display: flex;
              margin-bottom: 20px;

              small {
                font-size: 16px;
                border: 1px solid gray;
                border-radius: 4px;
                margin-right: 7px;
                padding: 2px 10px;
                text-transform: none;
              }
            }

            p {
              text-transform: none;
              margin-bottom: 1rem;
            }

            .author-box {
              display: flex;
              align-items: center;
              color: black;

              p {
                font-size: 14px;
                padding: 0 5px;
                color: black;
                margin-bottom: 0;

                :first-child {
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }

    #pagination-section {
      border-top: 1px dashed ${(props) => props.theme.colors.green};
      padding-top: 20px;
      width: 80%;
      display: flex;
      justify-content: center;
      margin: 0 auto;

      #inner {
        display: flex;
        justify-content: center;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${(props) => props.theme.colors.green};
          border-color: ${(props) => props.theme.colors.green};
          border-radius: 50px;
          background-color: ${(props) => props.theme.colors.white};
          width: 35px;
          height: 35px;
          text-decoration: none;
          cursor: pointer;
          padding: 7px;
          margin: 0 5px;

          :hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  @media (max-width: 610px) {
    #list-main {
      h1,
      p.intro {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
`
export const IsActive = styled.div`
  ${(props) =>
    props.active &&
    css`
      border: 1px solid ${(props) => props.theme.colors.green};
      border-radius: 90px;
      padding: 4px 9px;
      width: 35px;
      height: 35px;
      margin: 0 5px;
      background-color: ${(props) => props.theme.colors.green};
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    `};
`
